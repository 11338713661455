<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="PAYMENT_SENSE_CONNECT_E"
      :properties="properties"
      step-label="paymentSenseConnectE"
      :readonly="!canEdit"
      required
      :input-has-to-be-validated="true"
      @change="handleChange('credentialName', $event)"
    />

    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedPaymentSenseConnectEActions"
        :default-value="properties.action"
        step-label="paymentSenseConnectE"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col v-if="['CROSS_REFERENCE_PAYMENT', 'PAYMENT_DETAILS', 'RESUME_PAYMENT', 'REVOKE_ACCESS_TOKEN'].includes(properties.action)" cols="12">
      <v-text-field
        :key="`${step.id}-accessToken`"
        v-model="properties.parameters.accessToken"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-accessToken"
        :label="$lang.labels.accessToken"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
      />
      
    </v-col>

    <v-col v-if="properties.action === 'CROSS_REFERENCE_PAYMENT'" cols="12">
      <v-text-field
        :key="`${step.id}-crossReferencePayment-crossReference`"
        v-model="properties.parameters.crossReferencePayment.crossReference"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-crossReference"
        :label="$lang.labels.crossReference"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
      />
      <v-text-field
        :key="`${step.id}-crossReferencePayment-cv2`"
        v-model="properties.parameters.crossReferencePayment.cv2"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-cv2"
        :label="$lang.labels.cv2"
        :readonly="!canEdit"
      />
    </v-col>

    <v-col v-if="properties.action === 'ACCESS_TOKEN'" cols="12">
      <v-text-field
        :key="`${step.id}-amount`"
        v-model="properties.parameters.amount"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-amount"
        :label="$lang.labels.amount"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-crossReference`"
        v-model="properties.parameters.crossReference"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-crossReference"
        :label="$lang.labels.crossReference"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-currencyCode`"
        v-model="properties.parameters.currencyCode"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-currencyCode"
        :label="$lang.labels.currencyCode"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
      />
      <v-text-field
        :key="`${step.id}-customerId`"
        v-model="properties.parameters.customerId"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-customerId"
        :label="$lang.labels.customerId"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-merchantTransactionId`"
        v-model="properties.parameters.merchantTransactionId"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-merchantTransactionId"
        :label="$lang.labels.merchantTransactionId"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-merchantUrl`"
        v-model="properties.parameters.merchantUrl"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-merchantUrl"
        :label="$lang.labels.merchantUrl"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
      />
      <v-col cols="12" class="pt-1 pb-2">
        <add-key-value
          :key="`${step.id}-metaData`"
          type="metaData"
          :can-edit="canEdit"
          step-type="PAYMENT_SENSE_CONNECT_E"
          :data="properties.parameters.metaData"
          :single-step="step"
          :text-template-name="properties.textTemplate"
          @editor="$emit('openEditor', $event, 'PAYMENT_SENSE_CONNECT_E')"
          @dataChanged="handleChange('parameters.metaData', $event)"
        />
      </v-col>
      <v-checkbox
        :key="`${step.id}-newTransaction`"
        v-model="properties.parameters.newTransaction"
        dense
        data-cy="paymentSensePac-parameters-newTransaction"
        :label="$lang.labels.newTransaction"
      />
      <v-text-field
        :key="`${step.id}-orderDescription`"
        v-model="properties.parameters.orderDescription"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-orderDescription"
        :label="$lang.labels.orderDescription"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-orderId`"
        v-model="properties.parameters.orderId"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-orderId"
        :label="$lang.labels.orderId"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
      />
      <p>{{ $lang.labels.shippingDetails }}</p>
      <v-text-field
        :key="`${step.id}-shippingDetails-name`"
        v-model="properties.parameters.shippingDetails.name"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-name"
        :label="$lang.labels.name"
        :readonly="!canEdit"
      />
      <p>{{ $lang.labels.address }}</p>
      <v-text-field
        :key="`${step.id}-shippingDetails-userAddress1`"
        v-model="properties.parameters.shippingDetails.address.userAddress1"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-address-userAddress2"
        :label="$lang.labels.userAddress1"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-shippingDetails-userAddress2`"
        v-model="properties.parameters.shippingDetails.address.userAddress2"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-address-userAddress2"
        :label="$lang.labels.userAddress2"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-shippingDetails-userAddress3`"
        v-model="properties.parameters.shippingDetails.address.userAddress3"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-address-userAddress3"
        :label="$lang.labels.userAddress3"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-shippingDetails-userAddress4`"
        v-model="properties.parameters.shippingDetails.address.userAddress4"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-address-userAddress4"
        :label="$lang.labels.userAddress4"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-shippingDetails-userCity`"
        v-model="properties.parameters.shippingDetails.address.userCity"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-address-userCity"
        :label="$lang.labels.userCity"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-shippingDetails-userCountryCode`"
        v-model="properties.parameters.shippingDetails.address.userCountryCode"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-address-userCountryCode"
        :label="$lang.labels.userCountryCode"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-shippingDetails-userPostcode`"
        v-model="properties.parameters.shippingDetails.address.userPostcode"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-address-userPostcode"
        :label="$lang.labels.userPostcode"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-shippingDetails-userState`"
        v-model="properties.parameters.shippingDetails.address.userState"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-address-userState"
        :label="$lang.labels.userState"
        :readonly="!canEdit"
      />
      <v-divider />
      <v-select
        :key="`${step.id}-transactionSource`"
        v-model="properties.parameters.transactionSource"
        :items="TRANSACTION_SOURCES"
        :label="$lang.labels.transactionSource"
        outlined
        dense
        required
        data-cy="paymentSensePac-parameters-transactionSource"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
      />
      <v-select
        :key="`${step.id}-transactionType`"
        v-model="properties.parameters.transactionType"
        :items="TRANSACTION_TYPES"
        :label="$lang.labels.transactionType"
        outlined
        dense
        required
        data-cy="paymentSensePac-parameters-transactionType"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required]"
        class="required-asterisk"
      />
      <v-text-field
        :key="`${step.id}-userAddress1`"
        v-model="properties.parameters.userAddress1"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-userAddress1"
        :label="$lang.labels.userAddress1"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-userAddress2`"
        v-model="properties.parameters.userAddress2"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-userAddress2"
        :label="$lang.labels.userAddress2"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-userAddress3`"
        v-model="properties.parameters.userAddress3"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-userAddress3"
        :label="$lang.labels.userAddress3"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-userAddress4`"
        v-model="properties.parameters.userAddress4"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-userAddress4"
        :label="$lang.labels.userAddress4"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-userAgent`"
        v-model="properties.parameters.userAgent"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-userAgent"
        :label="$lang.labels.userAgent"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-userCity`"
        v-model="properties.parameters.userCity"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-userCity"
        :label="$lang.labels.userCity"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-userCountryCode`"
        v-model="properties.parameters.userCountryCode"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-userCountryCode"
        :label="$lang.labels.userCountryCode"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-userEmailAddress`"
        v-model="properties.parameters.userEmailAddress"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-userEmailAddress"
        :label="$lang.labels.userEmailAddress"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-userIpAddress`"
        v-model="properties.parameters.userIpAddress"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-userIpAddress"
        :label="$lang.labels.userIpAddress"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-userPhoneNumber`"
        v-model="properties.parameters.userPhoneNumber"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-userPhoneNumber"
        :label="$lang.labels.userPhoneNumber"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-userPostcode`"
        v-model="properties.parameters.userPostcode"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-userPostcode"
        :label="$lang.labels.userPostcode"
        :readonly="!canEdit"
      />
      <v-text-field
        :key="`${step.id}-userState`"
        v-model="properties.parameters.userState"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-userState"
        :label="$lang.labels.userState"
        :readonly="!canEdit"
      />
      <v-checkbox
        :key="`${step.id}-waitPreExecute`"
        v-model="properties.parameters.waitPreExecute"
        dense
        data-cy="paymentSensePac-parameters-waitPreExecute"
        :label="$lang.labels.waitPreExecute"
      />
      <v-text-field
        :key="`${step.id}-webHookUrl`"
        v-model="properties.parameters.webHookUrl"
        outlined
        dense
        data-cy="paymentSenseConnectE-parameters-webHookUrl"
        :label="$lang.labels.webHookUrl"
        :readonly="!canEdit"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        class="required-asterisk"
        data-cy="paymentSenseConnectE-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      />
    </v-col>
  </v-row>
</template>

<script>
import { CredentialsSelect, ActionSelect } from './components'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'

import {
  TRANSACTION_SOURCES,
  TRANSACTION_TYPES,
  PAYMENT_SENSE_CONNECT_E_ACTIONS
} from './constants'
import { getNestedPropertyForSetting } from '@/utils/helpers'

export default {
  name: 'PaymentSenseConnectEStep',
  components: {
    CredentialsSelect,
    ActionSelect,
    AddKeyValue
  },
  mixins: [StepMixin],
  data() {
    return {
      TRANSACTION_SOURCES, TRANSACTION_TYPES
    }
  },
  computed: {
    formattedPaymentSenseConnectEActions() {
      return PAYMENT_SENSE_CONNECT_E_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.parameters) this.properties.parameters = {
      accessToken: ''
    }
    if (this.properties.action === 'ACCESS_TOKEN') {
      if (!this.properties.parameters.metaData) this.properties.parameters.metaData = {}
      if (!this.properties.parameters.shippingDetails) this.properties.parameters.shippingDetails = {
        address: {
          userAddress1: '',
          userAddress2: '',
          userAddress3: '',
          userAddress4: '',
          userCity: '',
          userCountryCode: '',
          userPostcode: '',
          userState: ''
        },
        name: ''
      }
    }
  },
  methods: {
    handleChange(key, val) {
      if (key === 'action') {
        this.paymentSenseConnectEActionChanged(val)
      }
      if (key.includes('.')) {
        const { nestedObj, lastKey } = getNestedPropertyForSetting(this.properties, key)

        this.$set(nestedObj, lastKey, val)

        return
      }

      this.$set(this.properties, key, val)
    },
    paymentSenseConnectEActionChanged(newVal) {
      if (['PAYMENT_DETAILS', 'RESUME_PAYMENT', 'REVOKE_ACCESS_TOKEN'].includes(newVal)) {
        this.properties.parameters = {
          accessToken: ''
        }
      }
      if (['CROSS_REFERENCE_PAYMENT'].includes(newVal)) {
        this.properties.parameters = {
          accessToken: '',
          crossReferencePayment: {
            crossReference: '',
            cv2: ''
          }
        }
      }
      if (['ACCESS_TOKEN'].includes(newVal)) {
        this.properties.parameters = {
          amount: '',
          crossReference: '',
          currencyCode: '',
          customerId: '',
          merchantTransactionId: '',
          merchantUrl: '',
          metaData: {},
          newTransaction: true,
          orderDescription: '',
          orderId: '',
          shippingDetails: {
            address: {
              userAddress1: '',
              userAddress2: '',
              userAddress3: '',
              userAddress4: '',
              userCity: '',
              userCountryCode: '',
              userPostcode: '',
              userState: ''
            },
            name: ''
          },
          transactionSource: 'MOTO', // TODO make list
          transactionType: 'SALE', // TODO make list
          userAddress1: '',
          userAddress2: '',
          userAddress3: '',
          userAddress4: '',
          userAgent: '',
          userCity: '',
          userCountryCode: '',
          userEmailAddress: '',
          userIpAddress: '',
          userPhoneNumber: '',
          userPostcode: '',
          userState: '',
          waitPreExecute: false,
          webHookUrl: ''
        }
      }
      if (['PAYMENT_METHODS'].includes(newVal)) {
        this.properties.parameters = null
      }
    }
  }
}
</script>
